
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import { Component } from 'vue-property-decorator';

@Component({
  components: {
    BaseToolbar
  }
})
export default class MyListToolbar extends BaseToolbar {
  get localComponent(): any {
    return this.currentPageInstance || {};
  }

  get localSearch() {
    return this.localComponent.localSearch || '';
  }

  set localSearch(value: string) {
    this.localComponent.localSearch = value;
  }

  get hasSelectedRows() {
    return this.localComponent.hasSelectedRows;
  }

  get localAdminSelectedTab() {
    return this.localComponent.localAdminSelectedTab;
  }

  set localAdminSelectedTab(value: any) {
    this.localComponent.localAdminSelectedTab = value;
  }

  get localIsEditingLessonLayoutList() {
    return this.localComponent.localIsEditingLessonLayoutList;
  }

  get toggleEditMode() {
    return this.localComponent.toggleEditMode;
  }

  get addLessonLayout() {
    return this.localComponent.addLessonLayout || this.emptyFunction;
  }

  get removeLessonLayouts() {
    return this.localComponent.removeLessonLayouts || this.emptyFunction;
  }

  get isActionable() {
    return this.localComponent.isActionable;
  }
}
