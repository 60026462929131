






























































import { Component } from 'vue-property-decorator';
import MyListToolbar from './MyListToolbar.vue';

@Component
export default class MyListBottomToolbar extends MyListToolbar {
}
